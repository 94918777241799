import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConstantsService } from './constants.service';
import { payments, Payments, Card, GooglePay, PaymentMethod, ApplePay } from '@square/web-sdk';
import { BehaviorSubject, Observable } from 'rxjs';
import { CatalogItem, CatalogObject, Customer, ListCatalogResponse, Payment } from 'square-connect';

const darkModeCardStyle = {
  '.input-container': {
    borderColor: 'white',
    borderRadius: '0px',
  },
  '.input-container.is-focus': {
    borderColor: '#006AFF',
  },
  '.input-container.is-error': {
    borderColor: '#ff1600',
  },
  '.message-text': {
    color: '#999999',
  },
  '.message-icon': {
    color: '#999999',
  },
  '.message-text.is-error': {
    color: '#ff1600',
  },
  '.message-icon.is-error': {
    color: '#ff1600',
  },
  input: {
    backgroundColor: 'transparent',
    color: '#FFFFFF',
    fontSize: '16px',
  },
  'input::placeholder': {
    color: '#999999',
  },
  'input.is-error': {
    color: '#ff1600',
  },
  '@media screen and (max-width: 600px)': {
    'input': {
      'fontSize': '12px',
    }
  }
};

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private payments: Payments | undefined;
  private card: Card | undefined;
  public catalogItems: any;
  private googlePay: GooglePay;
  private paymentRequest
  private applePay: ApplePay;
  private selectedItemData: any;

  private selectedItemSubject = new BehaviorSubject<any>(null);
  selectedItem: Observable<any> = this.selectedItemSubject.asObservable();

  constructor(
    private http: HttpClient,
    private constants: ConstantsService
  ) { // Subscribe to changes of selectedItem
    this.selectedItem.subscribe((item) => {
      if (item != undefined && item?.itemData != this.selectedItemData) {
        this.selectedItemData = item?.itemData;
        // Perform action when the selected item changes
        this.initializeGooglePay();
      }
    });
  }

  async getCatalog() {
    try {
      const catalogItems = await this.getCreditProductCatalog();

      // Assuming catalogItems.objects is an array of CatalogObject
      if (catalogItems.objects && catalogItems.objects.length > 0) {
        this.catalogItems = catalogItems.objects;
        this.updateSelectedItem(this.catalogItems[0]);  // Preselect the first item
      }
    } catch (error) {
      console.error('Error fetching catalog items:', error);
    }
  }

  buildPaymentRequest(payments: Payments) {
    return payments.paymentRequest({
      countryCode: 'US',
      currencyCode: this.selectedItemData?.variations[0].itemVariationData.priceMoney.currency,
      total: {
        amount: String(this.moveDecimalLeft(this.selectedItemData?.variations[0].itemVariationData.priceMoney.amount, 2)),
        label: this.selectedItemData?.name,
      },
    });
  }

  // Change selectedItem (product)
  updateSelectedItem(selectedItem: any) {
    this.selectedItemSubject.next(selectedItem);
  }

  async getCustomer(userId: string): Promise<any> {
    const url = `${this.constants.API_BASE_URL}/payment/get-customer/${userId}`;
    const headers = new HttpHeaders({ "Content-type": "application/json" });
    try {
      return await this.http.get<any>(url, { headers }).toPromise()
    } catch (error) {
      console.error('Error getting customer:', error);
      throw error;
    }
  }

  async getCreditProductCatalog(): Promise<any> {
    const url = `${this.constants.API_BASE_URL}/payment/get-catalog`;
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    try {
      return await this.http.get<any>(url, { headers }).toPromise();
    } catch (error) {
      console.error('Error fetching product catalog:', error);
      throw error;
    }
  }

  // Initialize the Square Payments instance
  async initializeSquare(applicationId: string, locationId: string): Promise<Payments> {
    try {
      this.payments = await payments(applicationId, locationId);
      return this.payments;
    } catch (error) {
      console.error('Error initializing Square Payments:', error);
      throw error;
    }
  }

  // Initialize Card Payment method
  async initializeCard(): Promise<Card> {
    if (this.payments) {
      try {
        this.card = await this.payments.card({
          style: darkModeCardStyle,
        });
        await this.card.attach('#card-container');
        return this.card
      } catch (error) {
        console.error('Error initializing Card payment method:', error);
        throw error;
      }
    } else {
      throw new Error('Payments instance is not initialized');
    }
  }

  async initializeApplePay(): Promise<any> {
    if (this.payments) {
      this.paymentRequest = this.buildPaymentRequest(this.payments);
      try {
        const applePay = await this.payments.applePay(this.paymentRequest);
        return applePay;
      } catch (error) {
        console.error('Error initializing Apple Pay:', error);
        throw error;
      }
    } else {
      throw new Error('Payments instance is not initialized');
    }
  }

  async initializeGooglePay(): Promise<any> {

    if (this.payments) {
      if (this.paymentRequest) {
        await this.googlePay.destroy()
      }
      this.paymentRequest = this.buildPaymentRequest(this.payments);
      try {
        this.googlePay = await this.payments.googlePay(this.paymentRequest);
        await this.googlePay.attach('#google-pay-button',{buttonSizeMode: 'fill'});
      } catch (error) {
        console.error('Error initializing Google Pay:', error);
        throw error;
      }
    } else {
      throw new Error('Payments instance is not initialized');
    }

  }

  // Tokenize and return the payment nonce
  async tokenizeCard(paymentMethod?: any): Promise<string> {
    if (!paymentMethod) {
      if (!this.card) throw new Error('Card is not initialized');
      try {
        const result = await this.card.tokenize();
        if (result.status === 'OK') {
          return result.token;
        } else {
          throw new Error(result.errors ? result.errors[0].message : 'Card Tokenization failed');
        }
      } catch (error) {
        console.error('Error tokenizing card:', error);
        throw error;
      }

    }
    // Tokenize other payment methods
    if (paymentMethod == "Google Pay") {
      this.googlePay.tokenize()
    } else if (paymentMethod == "Apple Pay") {

    } else {
      return;
    }

  }

  // Process card payment
  async processPayment(userId: string, selectedItem: any, token?: string): Promise<any> {
    const url = `${this.constants.API_BASE_URL}/payment/process-payment`;
    try {
      return await this.http.post<any>(url, { userId: userId, nonce: token, catalogItemId: selectedItem.id }).toPromise();
    } catch (error) {
      console.error('Error processing payment:', error);
      throw error;
    }
  }

  // Store card on square
  async storeCard(token: string, userId: string) {
    const url = `${this.constants.API_BASE_URL}/payment/store-card`;
    try {
      return await this.http.post<any>(url, { token, userId }).toPromise();
    } catch (error) {
      console.error('Error storing user card', error)
      throw error;
    }
  }

  moveDecimalLeft(num: number, places: number): number {
    return num / Math.pow(10, places);
  }
}
